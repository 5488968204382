import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import  ReactTooltip from 'react-tooltip'
import { AppWrap, MotionWrap } from '../../wrapper';
import { urlFor, client } from '../../client';
import "./Events.scss";

const Events = () => {
  const [events, setEvents] = useState([]);
  const [workshops, setWorkshops] = useState([]);
  

  useEffect(() => {
    const query = '*[_type =="events"]';
    const WorkshospQuery = '*[_type =="workshops"]';
    client.fetch((query))
      .then((data) => {
        setEvents(data);
      });

    client.fetch((WorkshospQuery))
      .then((data) => {
        setWorkshops(data);
      })

    return () => {
    }
  }, [])
  return (
    <>
      <h2 className="head-text">Workshops & Events</h2>

      <div className="app__skills-container">
        <motion.div className='app__skills-list'>
          {workshops?.map((workshop) => (
            <motion.div
              whileInView={{ opacity: [0, 1] }}
              transition={{ duration: 0.5 }}
              className='app__skills-item app__flex'
              key={workshop.name}
            >
              <div className="app__flex" style={{ backgroundColor: workshop.bgColor }}>
                <img src={urlFor(workshop.icon)} alt={workshop.name} />
              </div>
              <p className="p-text">{workshop.name}</p>
            </motion.div>
          ))}
        </motion.div>
        <div className='app__skills-exp' >
          {events.map((event) => (
            <motion.div 
            className='app__skills-exp-item'
            key={events.year}
            >
              <div className="app__skills-exp-year">
                <p className="bold-text">{event.year}</p>
              </div>
              <motion.div className="app__skills-exp-works">
                {event.eventwork?.map((work)=>(
                  <>
                    <motion.div
                      whileInView={{ opacity: [0, 1] }}
                      transition={{ duration: 0.5 }}
                      className="app__skills-exp-work"
                      data-tip
                      data-for={work.name}
                      key={work.name}
                    >
                      <h4 className="bold-text">{work.name}</h4>
                      <p className="p-text">{work.date}</p>
                      <p className="p-text">{work.venue}</p>
                    </motion.div>
                    <ReactTooltip
                      id={work.name}
                      effect="solid"
                      arrowColor="#fff"
                      className="skills-tooltip"
                    >
                      {work.desc}
                    </ReactTooltip>
                  </>
                ))}
          </motion.div>
        </motion.div>
          ))}
      </div>
      </div>
    </>
  )
}

export default AppWrap(
  MotionWrap(Events, 'app__skills'),
  "events",
  'app__whitebg',
);
import React from 'react';
import { About, Footer, Header, Events, Testimonials, Members } from './containers';
import { Navbar } from './components';
import './App.scss';
function App() {
  return (
    <div className="app">
    <Navbar/>
    <Header/>
    <About/>
    <Members/>
    <Events/>
    <Testimonials/>
    <Footer/>
    </div>
  );
}

export default App;

import React from 'react'
import { BsTwitter, BsInstagram } from 'react-icons/bs';
import { FaReddit } from 'react-icons/fa';

const SocialMedia = () => {
    return (
        <div className='app__social'>
        <div>
        <a href="https://twitter.com/Hackhiveclub" target='_blank'><BsTwitter/></a>
        </div>
        <div>
        <a href="https://www.reddit.com/user/HackHiveClub" target='_blank'><FaReddit/></a>
        </div>
        <div>
            <a href="https://www.instagram.com/hackhiveclub/" target='_blank'><BsInstagram/></a>
        </div>

        </div>
    )
}

export default SocialMedia
import React, {useState, useEffect} from 'react';
import {AiFillInstagram, AiFillGithub} from 'react-icons/ai';
import "./Members.scss";
import { motion } from 'framer-motion';

import { AppWrap, MotionWrap } from '../../wrapper';
import { urlFor, client } from '../../client';


const Members = () => {
  const [activeFilter, setActiveFilter] = useState('All');
  const [animateCard, setAnimateCard] = useState({y:0,opacity:1});
  const [members, setMembers] = useState([]);
  const [filterMembers, setFilterMembers] = useState([]);

  useEffect(() => {
    const query = '*[_type =="members"]';
    client.fetch((query))
    .then((data)=>{
      setMembers(data);
      setFilterMembers(data);
    })
  
    return () => {
    }
  }, [])
  
  const handleWorkFilter= (item)=>{
      setActiveFilter(item);
      setAnimateCard([{y:100, opacity: 0}])

      setTimeout(() => {
        setAnimateCard([{y:0, opacity:1}]);
        setFilterMembers(members.filter((member)=> member.tags.includes(item)));
      }, 500);
  }
  return (
    <>
      <h2 className="head-text">Our Talented <span>Members</span></h2>

      <div className="app__work-filter">
        {['Council','Active Members', 'Mentors', 'Alumni', 'All'].map((item, index) => (
          <div
          key={index}
          onClick={()=>handleWorkFilter(item)}
          className={`app__work-filter-item app__flex p-text ${activeFilter===item ? 'item-active' : ''}`}
          >
          {item}
          </div>
        ))}
      </div>

      <motion.div
      animate={animateCard}
      transition={{duration:0.5, delayChildren: 0.5}}
      className="app__work-portfolio"
      >
        {filterMembers.map((member, index)=>(
          <div className="app__work-item app__flex" key={index}>
          <div className="app__work-img app__flex">
            <img
              src={urlFor(member.imgUrl)}
              alt={member.name}
            />
            <motion.div
            whileHover={{opacity: [0,1]}}
            transition={{duration:0.25, ease: 'easeInOut', staggerChildren: 0.5}}
            className='app__work-hover app__flex'
            >
            <a href={member.instagramLink} target='_blank' rel='noreferrer'>
              <motion.div
              whileInView={{scale:[0,1]}}
              whileHover={{opacity: [1,0.9]}}
            transition={{duration:0.25}}
            className='app__flex'
              >
                <AiFillInstagram/>
              </motion.div>
            </a>
            <a href={member.projectLink} target='_blank' rel='noreferrer'>
              <motion.div
              whileInView={{scale:[0,1]}}
              whileHover={{opacity: [1,0.9]}}
            transition={{duration:0.25}}
            className='app__flex'
              >
                <AiFillGithub/>
              </motion.div>
            </a>

            </motion.div>
          </div>
          <div className="app__work-content app__flex">
            <h3 className="bold-text">{member.name}</h3>
            <h4 className="bold-text">{member.title}</h4>
            <p className="p-text" style={{marginTop:10}}>{member.description}</p>
            <div className="app__work-tag app__flex">
              <p className="p_text">{member.tags[0]}</p>
            </div>
          </div>
          </div>
        ))}
      </motion.div>
    </>
  )
}

export default AppWrap(
  MotionWrap(Members, 'app__works'),
  'members',
  'app__primarybg',
);